import { useContext } from 'react';
import Dashboard from './dashboard';
import Rotinas from './rotinas';
import Configuracoes from './configuracoes';
import MenuCliente from './menuCliente';

import { AuthContext } from '../contexts/auth';

const useMenuItems = () => {
  const { user } = useContext(AuthContext);

  if (user.funcao == "admin") {
    return {
      items: [Dashboard(user), Rotinas(user), MenuCliente(user), Configuracoes(user)]
    };
  } else if (user.funcao == "analista") {
    return {
      items: [Rotinas(user), MenuCliente(user), Configuracoes(user)]
    };
  } else {
    return {
      items: [Dashboard(user), MenuCliente(user)]
    };
  }
}

export default useMenuItems;