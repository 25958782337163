// material-ui
// import logo from '../assets/images/Logo-LCR-Conecta.png';
import logo from '../assets/images/Logo_horizontal.png';


/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoHome = ({ width, height, borderRadius, paddingTop }) => {

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Berry" width="100" />
     *
     */
    <img src={logo} alt="lcr-conecta" style={{ width: width || '142px', height: height || 'auto', borderRadius: borderRadius || '', paddingTop: paddingTop || 0 }} />
    // <img src={logo} style={{ width: '150px' }} alt="lcr-conecta" width="100" />

  );
};

export default LogoHome;
