/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

import { AuthContext } from "../contexts/auth";

export default function RequireAuth({ children, redirectTo, isPrivate }) {
  const { signed, load } = useContext(AuthContext);

  function getAuth() {
    return signed;
  }

  if (load) {
    return <div />;
  }



  const isAuthenticated = getAuth();
  getAuth();

  if (isPrivate) {
    return isAuthenticated ? children : <Navigate to={redirectTo} />;
  }

  return isAuthenticated ? <Navigate to={redirectTo} /> : children;
}
