import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ToastContainer } from "react-toastify";
import { LicenseInfo } from "@mui/x-license-pro";

import Routes from 'routes';

import themes from 'themes';
import AuthProvider from "./contexts/auth";
import CrudProvider from "./contexts/crud";

import NavigationScroll from 'layout/NavigationScroll';
import "react-toastify/dist/ReactToastify.css";


const App = () => {
  const customization = useSelector((state) => state.customization);
  LicenseInfo.setLicenseKey(process.env.REACT_APP_KEY_MATERIAL);

  return (

    <StyledEngineProvider injectFirst>
      <AuthProvider>
        <CrudProvider>
          <ThemeProvider theme={themes(customization)}>
            <CssBaseline />
            <NavigationScroll>
              <ToastContainer autoClose={3000} />
              <Routes />
            </NavigationScroll>
          </ThemeProvider>
        </CrudProvider>
      </AuthProvider>
    </StyledEngineProvider >

  );
};

export default App;
