import { IconUser, IconHeartHandshake, IconBusinessplan, IconUsers,IconPercentage } from '@tabler/icons';

const icons = {
  IconUser,
  IconHeartHandshake,
  IconBusinessplan,
  IconUsers, 
  IconPercentage
};

const Configuracoes = (user) => {

  const configuracoes = {
    id: 'configuracoes',
    title: 'Configurações',
    type: 'group',
    children: [
      {
        id: 'clientes',
        title: 'Clientes',
        type: 'item',
        url: '/configuracoes/clientes',
        icon: icons.IconHeartHandshake,
        breadcrumbs: false
      },
      {
        id: 'usuarios',
        title: 'Usuários',
        type: 'item',
        url: '/configuracoes/usuario',
        icon: icons.IconUser,
        breadcrumbs: false
      },
      ...(user.funcao === 'admin' ? [
        {
          id: 'colaboradores',
          title: 'Colaboradores',
          type: 'item',
          url: '/configuracoes/colaboradores',
          icon: icons.IconUsers,
          breadcrumbs: false
        },
      ] : []),
      ...(user.funcao === 'admin' ? [
        {
          id: 'departamentos',
          title: 'Departamentos',
          type: 'item',
          url: '/configuracoes/departamentos',
          icon: icons.IconBusinessplan,
          breadcrumbs: false
        },
        {
          id: 'aliquotas',
          title: 'Aliquotas',
          type: 'item',
          url: '/configuracoes/aliquotas',
          icon: icons.IconPercentage,
          breadcrumbs: false
        },
        {
          id: 'empresaServicos',
          title: 'Empresas x Serviços',
          type: 'item',
          url: '/configuracoes/empresaServicos',
          icon: icons.IconPercentage,
          breadcrumbs: false
        },
      ] : []),
    ]
  };

  return configuracoes
}

export default Configuracoes;
