import { IconDashboard, IconChartHistogram } from '@tabler/icons';

const icons = { IconDashboard, IconChartHistogram };

const Dashboard = (user) => {

  const dashboard = {
    id: 'dashboard',
    title: 'Home',
    type: 'group',
    children: [
      ...(user.funcao === 'admin' ? [
        {
          id: 'defaultAdmin',
          title: 'Visão Geral - Admin',
          type: 'item',
          url: '/dashboard/admin',
          icon: icons.IconDashboard,
          breadcrumbs: false
        }
      ] : []),
      ...(user.funcao === 'cliente' ? [
        {
          id: 'default',
          title: 'Visão Geral',
          type: 'item',
          url: '/dashboard/default',
          icon: icons.IconDashboard,
          breadcrumbs: false
        },
      ] : []),
      ...(user.funcao === 'cliente' ? [
        {
          id: 'clientEvoluteAnalysis',
          title: 'Análise Evolutiva',
          type: 'item',
          url: '/dashboard/evoluteAnalysis',
          icon: icons.IconChartHistogram,
          breadcrumbs: false
        },
      ] : [])
    ]
  };

  return dashboard
}

export default Dashboard;
