/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, createContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";

import { toast } from "react-toastify";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [User, setUser] = useState(null);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    const storageUser = localStorage.getItem("user");
    if (storageUser) {
      setUser(JSON.parse(storageUser));
      setLoad(false);
    }
    setLoad(false);
  }, []);

  const urlBase = process.env.REACT_APP_LCR_API;  

  const signOut = async () => {
    localStorage.removeItem("user");
    toast.success("Desconectado");
    setUser(null);
  };

  const signIn = async (email, password) => {
    try {
      const payload = {
        email,
        password,
      };

      const response = await axios.post(`${urlBase}/user/auth`, payload);

      return response.data
    } catch (error) {
      console.log("error: ", error.response);
      toast.error(error.response.data.message);
      // throw error;
    }
  };

  const signInConfirm = async (user) => {
    setLoad(true);

    setUser(user);
    localStorage.setItem("user", JSON.stringify(user));
    toast.success("Bem vindo de volta");

    setLoad(false);

    return user;
  };

  return (
    <AuthContext.Provider
      value={{
        signed: !!User,
        user: User,
        load,
        signOut,
        signIn,
        signInConfirm,
        setUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default AuthProvider;
