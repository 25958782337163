import { lazy } from 'react';

import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import RequireAuth from "./requireAuth";

const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const ForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication/Forgot')));

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/login',
      element: <RequireAuth redirectTo="/"><AuthLogin /></RequireAuth>
    },
    {
      path: '/forgot',
      element: <ForgotPassword />
    }
  ]
};

export default AuthenticationRoutes;
